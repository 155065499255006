.home{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
}

.wrapper {
    width:100%;
    max-width: 1440px;
}

.home__firstBar{
    display:flex;
    flex-direction: row;
    align-items: center;
}

.home__firstBarMiddle{
    width:100%;
}