.login__wrapper {
  height: calc(100vh - 162px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login__title {
  font-family: "Bebas Neue";
  font-size: 60px;
}

.login__inputs {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.login__inputsEmailInput,
.login__inputsPasswordInput {
  width: 300px;
  border-top: none;
  border-bottom: 2px solid black;
  border-right: none;
  border-left: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto";
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: rgb(241, 241, 241);
}

.login__inputsSubmitButton {
  width: 300px;
  height: 50px;
  background-color: black;
  border-radius: 300px;
  border: none;
  color: white;
  font-size: 22px;
  font-family: "Roboto";
  cursor: pointer;
}
