*,
*:before,
*:after {
  margin: 0pt;
  padding: 0pt;
  box-sizing: border-box;
}

input:disabled {
  background-color: lightgrey !important;
  cursor: not-allowed;
}

